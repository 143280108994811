import * as s from './App.style';
import { useLayoutEffect, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

// Context
import AppContext from './Context';

// Routes
import Routes from 'Routes';

// Components
import Navbar from 'components/Navbar/Navbar';
import FullScreenImage from 'components/FullScreenImage/FullScreenImage';

// Constants
import { allPerformersFromAllTime, currentPerformersList } from 'Performers';

const measurementId = 'G-73SR575R1G';

function App() {
  ReactGA.initialize(measurementId);
  ReactGA.pageview(window.location.pathname + window.location.search);

  const [isMobile, setIsMobile] = useState(false);
  const [isPhone, setIsPhone] = useState(false);
  const [currentPath, setCurrentPath] = useState('/');
  const [isNavbarOpenMobile, setIsNavbarOpenMobile] = useState(false);
  const [allPerformers, setAllPerformers] = useState([]);
  const [fullScreenImg, setFullScreenImg] = useState(null);

  const [actForm, setActForm] = useState({
    act_description: '',
    band_name: '',
    contact_name: '',
    email: '',
    hasOwnPorch: false,
    lights: false,
    phone: '',
    porch_address: ''
  });

  const [porchForm, setPorchForm] = useState({
    act_description: '',
    address: '',
    email: '',
    hasOwnAct: false,
    lights: false,
    name: '',
    name_of_act: '',
    phone: ''
  });

  const [selectedPerformer, setSelectedPerformer] = useState({
    name: '',
    bio: '',
    photo: '',
    genre: '',
    links: []
  });

  // Listen to URL updates
  const location = useLocation();
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  // Merge all cols of artists into one array
  useEffect(() => {
    setAllPerformers(
      allPerformersFromAllTime.col1
        .concat(allPerformersFromAllTime.col2)
        .concat(allPerformersFromAllTime.col3)
        .concat(currentPerformersList.col1)
        .concat(currentPerformersList.col2)
        .concat(currentPerformersList.col3)
    );
  }, [location]);

  // Set screen type
  useLayoutEffect(() => {
    if (window.innerWidth < 1200) setIsMobile(true);
    if (window.innerWidth < 570) setIsPhone(true);
  }, []);

  // Listen to window resizing
  useEffect(() => {
    const updateWindowWidth = () => {
      if (window.innerWidth < 1200) setIsMobile(true);
      else setIsMobile(false);

      if (window.innerWidth < 570) setIsPhone(true);
    };
    window.addEventListener('resize', updateWindowWidth);
    return () => window.removeEventListener('resize', updateWindowWidth);
  }, []);

  const handleSelectPerformer = (name) => {
    const clickedPerformer = allPerformers.find((performer) => performer.name === name);
    setSelectedPerformer(clickedPerformer);
  };

  const context = {
    actForm,
    currentPath,
    isPhone,
    isMobile,
    isNavbarOpenMobile,
    porchForm,
    selectedPerformer,
    fullScreenImg,
    setActForm,
    setIsNavbarOpenMobile,
    setPorchForm,
    setFullScreenImg,
    handleSelectPerformer
  };

  return (
    <AppContext.Provider value={context}>
      {fullScreenImg && <FullScreenImage />}

      <s.App id="galt-porchfest-container">
        {!fullScreenImg && <Navbar />}

        {!fullScreenImg && (
          <s.Footer>
            <s.Email href="mailto:hello@galtporchfest.ca">hello@galtporchfest.ca</s.Email>
            <s.SocialAndCopyright>
              <s.IGIcon src="icons/ig_blue.png" onClick={() => window.open('https://www.instagram.com/Galtporchfest/', '_blank')} />
              <s.FBIcon src="icons/fb_blue.png" onClick={() => window.open('https://www.facebook.com/galtporchfest/', '_blank')} />
              <s.CopyrightMessage>
                ©Copyright 2025. Created by{' '}
                <span style={{ cursor: 'pointer' }} onClick={() => window.open('https://ca.linkedin.com/in/eugenecostov', '_blank')}>
                  Eugene Costov
                </span>
              </s.CopyrightMessage>
            </s.SocialAndCopyright>
          </s.Footer>
        )}

        <s.DarkOverlay show={isNavbarOpenMobile} onClick={() => setIsNavbarOpenMobile(!isNavbarOpenMobile)} />

        <s.MainView isCurrentPageGallery={currentPath === '/gallery'}>
          <Routes />
        </s.MainView>
      </s.App>
    </AppContext.Provider>
  );
}

export default App;
