import * as s from './Home.style';
import CoolButton from 'components/Button/Button';

import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useContext, useEffect } from 'react';
import AppContext from 'Context';

const Home = () => {
  const history = useHistory();
  // Context
  const context = useContext(AppContext);
  const { isMobile } = context;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.nav initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.2 }}>
      <s.HomeContainer>
        <s.Header>
          {/* Welcome to 2024 <span style={{ color: '#ff7a46' }}>G</span>alt <span style={{ color: '#ff7a46' }}>P</span>orchfest! */}
          2025 <span style={{ color: '#ff7a46' }}>G</span>alt <span style={{ color: '#ff7a46' }}>P</span>orchfest
        </s.Header>
        {/* <s.DateDesktop>
          SATURDAY
          <br />
          August 24th 2024, 12pm-7pm
          <br />
          <s.ScheduleLink>
            <a href="/schedule">Click here</a> for full schedule with addresses Schedule with porch addresses will be available in June.
          </s.ScheduleLink>
        </s.DateDesktop> */}
        <s.DateDesktop>
          <img src="icons/warning.png" style={{ width: '26px', margin: '10px 0' }} alt="warning" />
          <br />
          Hey Porchfest fans!
          <br />
          <s.ScheduleLink>
            In <span style={{ color: '#ff7a46', fontWeight: 900 }}>2025</span>, we’ll be swapping guitars for diaper bags and porch jams for nap time. With new little rockstars
            joining our families, the festival will be taking a break. Thanks for understanding, and we’ll be back with more music (and maybe some baby tambourines) soon!
          </s.ScheduleLink>
        </s.DateDesktop>

        <s.ContentContainer>
          {/* Show date on phones only */}
          {/* <s.DateMobile>
            Saturday
            <br />
            August 24th 2024, 12pm-7pm
            <br />
            <s.ScheduleLink>
              <a href="/schedule">Click here</a> for full schedule and addresses Schedule with porch addresses will be available in June.
            </s.ScheduleLink>
          </s.DateMobile> */}
          <s.DateMobile>
            <img src="icons/warning.png" style={{ width: '26px', margin: '10px 0' }} alt="warning" />
            <br />
            Hey Porchfest fans!
            <br />
            <s.ScheduleLink>
              In <span style={{ color: '#ff7a46', fontWeight: 900 }}>2025</span>, we’ll be swapping guitars for diaper bags and porch jams for nap time. With new little rockstars
              joining our families, the festival will be taking a break. Thanks for understanding, and we’ll be back with more music (and maybe some baby tambourines) soon!
            </s.ScheduleLink>
          </s.DateMobile>
          <s.SponsorsBox>
            <s.ImgLogo src="img/logo_grey_square_2022.png" />
            <h5>Sponsored by</h5>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: isMobile ? '100%' : '260px',
                height: 'auto',
                alignItems: 'center',
                gap: '15px',
                marginBottom: isMobile ? '12px' : 0
              }}
            >
              <div style={{ display: 'flex', gap: '12px' }}>
                <s.SponsorImg isMobile={isMobile} src="img/home_page/blue_dog_marketing.png" onClick={() => window.open('https://google.ca/', '_blank')} />
                <s.SponsorImg isMobile={isMobile} src="img/home_page/maid_in_heaven.png" onClick={() => window.open('https://maidinheavencambridge.com/', '_blank')} />
                <s.SponsorImg isMobile={isMobile} src="img/home_page/adriatic_contracting.png" onClick={() => window.open('https://adriatic-con.ca/', '_blank')} />
              </div>
              <s.SponsorImg
                isMobile={isMobile}
                src="img/home_page/oakwood_counselling.png"
                onClick={() => window.open('https://www.oakwoodcounselling.ca/', '_blank')}
                style={{ height: isMobile ? '50px' : '44px' }}
              />
              <s.SponsorImg
                isMobile={isMobile}
                src="img/home_page/nuera.png"
                onClick={() => window.open('https://shipnuera.com/', '_blank')}
                style={{ marginTop: '-10px', height: isMobile ? '56px' : 'auto' }}
              />
              <s.SponsorImg
                isMobile={isMobile}
                src="img/home_page/hd_mutual.png"
                onClick={() => window.open('https://hdmutual.com/', '_blank')}
                style={{ marginTop: '-10px', height: isMobile ? '50px' : '46px' }}
              />
            </div>
          </s.SponsorsBox>
          <s.Text>
            Galt Porchfest is a community music festival held in Cambridge, Ontario where locals perform a set on their front porches for their neighbours who go house to house.
            <br />
            <br />
            It is a free event meant to bring the community together and discover the local talents of their community. If you are not a local or do not have a front porch, you are
            still welcome to perform. We will team you up with a local porch in Galt.
            <br />
            <br />
            Length of Set: 20 min - 1 hour
            <br />
            <br />
            Types of performances accepted: all genres of music, comedy skits, spoken word/poetry, magic.
            {!isMobile && (
              <s.ButtonContainer>
                <CoolButton label="SIGN UP" onClick={() => history.push('sign-up')} />
              </s.ButtonContainer>
            )}
          </s.Text>
        </s.ContentContainer>

        {isMobile && (
          <s.ButtonContainer isMobile>
            <CoolButton label="SIGN UP" onClick={() => history.push('sign-up')} />
          </s.ButtonContainer>
        )}

        <hr />

        <s.ImgFromFestWrapper>
          <s.ImgFromFest src={`img/home_page/01.jpg`} />
          <s.ImgFromFest src={`img/home_page/02.jpg`} />
          <s.ImgFromFest src={`img/home_page/03.jpg`} />
          <s.ImgFromFest src={`img/home_page/04.jpg`} />
          <s.ImgFromFest src={`img/home_page/05.jpg`} />
          <s.ImgFromFest src={`img/home_page/06.jpg`} />
          <s.ImgFromFest src={`img/home_page/07.jpg`} />
          <s.ImgFromFest src={`img/home_page/08.jpg`} />
          <s.ImgFromFest src={`img/home_page/09.jpg`} />
        </s.ImgFromFestWrapper>
      </s.HomeContainer>
    </motion.nav>
  );
};

export default Home;
